<template>
  <v-card>
    <v-card-title
      >
      Nuevo usuario
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      {{ users }}
      <v-divider></v-divider>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
        <v-text-field
          label="Email"
          v-model="user.email"
          placeholder="test@test.com"
          :rules="rules.email"
          outlined
          >
        </v-text-field>
        <v-text-field
          label="Password"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPass = !showPass"
          v-model="user.password"
          :rules="rules.password"
          outlined
          >
        </v-text-field>
        <v-autocomplete
          label="Tipo de usuario"
          v-model="user.role"
          :items="roles"
          item-text="name"
          item-value="value"
          :rules="rules.required"
          outlined
          >
        </v-autocomplete>
      </v-form>

      <div
        class="mt-3 text-right"
        >
        <v-btn
          tile
          depressed
          :disabled="loading"
          :loading="loading"
          class="success"
          @click="createUser"
          >
          Crear
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Users } from '@/graphql/queries/users'
import { CreateUser } from '@/graphql/mutations/users'
import { Email, Password } from '@/utils/rules'
import { Roles } from '@/utils/roles'

export default {
  data () {
    return {
      showPass: false,
      valid: true,
      loading: false,
      rules: {
        required: [
          v => !!v || 'Campo obligatorio'
        ],
        email: [
          v => !!v || 'Campo obligatorio'
        ],
        password: [
          v => !!v || 'Campo obligatorio'
        ]
      },
      roles: Roles,
      user: {
        email: null,
        password: null,
        role: 'rf'
      }
    }
  },

  methods: {
    createUser () {
      this.valid = this.$refs.form.validate()

      if (this.valid) {
        this.loading = true
        this.$apollo.mutate({
          mutation: CreateUser,
          variables: {
            input: {
              credentials: {
                email: this.user.email,
                password: this.user.password
              },
              role: this.user.role
            }
          }
        }).then( res => {
          this.loading = false
          this.$emit('reload')
          this.$emit('close')
        })
      }
    }
  }
}
</script>
